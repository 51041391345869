<template>
  <div class="container">
    <div class="container-deck-picker">
      <div class="hero-container">
        <div class="hero">
          <div class="hero-text">
            <div class="hero-1">Investing in Futures</div>
            <div class="hero-tagline">Future Worlds Simulator</div>
            <div class="hero-blurb">
              A set of worldbuilding decks that invite you to imagine new
              realities and invent a world you want to live in, regardless of
              how wild, impractical, idyllic, sensible or utopian.
            </div>
          </div>
          <div class="hero-image">
            <!-- <img src="@/assets/IIF_hand_image-cropped.jpg" /> -->
          </div>
        </div>
      </div>
      <div class="deck-selection-div">
        <h2>Begin by Selecting a Deck</h2>
        <h2 class="double-click-prompt">Double Click to Open</h2>
        <div class="deck-lineup">
          <div
            class="deck-button"
            v-for="deck in decks"
            :key="deck.name"
            :class="deck.isSelected ? 'selected' : ''"
            @mousedown="setAsSelected(deck.name)"
            @dblclick="openDeck(deck.name)"
          >
            <div class="deck-art"></div>
            <div class="deck-name" :class="deck.isSelected ? 'selected' : ''">
              {{ deck.name }}
            </div>
            <div class="created-by" v-if="deck.name != 'Original'">
              edition designed with
            </div>
            <div class="deck-creator-name">{{ deck.creator }}</div>
            <p class="blurb">{{ deck.blurb }}</p>
          </div>
        </div>
        <div class="button-div">
          <router-link
            :to="{ name: 'GameMain', params: { deckId: userSelectedDeck } }"
            :disabled="!validDeckSelected"
          >
            <button :disabled="!validDeckSelected">Open Deck</button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getAllDecks } from "@/services/DeckService.js";
export default {
  name: "Deck Picker",
  data() {
    return {
      selectedDeck: undefined,
      decks: [
        // {
        //   name: "Original",
        //   author: "More&More Unlimited",
        //   blurb: "",
        //   imgURL: "",
        //   isSelected: false,
        // },
      ],
    };
  },
  methods: {
    fetchDecks() {
      getAllDecks()
        .then((theData) => {
          this.decks = theData;
          console.log("Setting up decks data: ", theData);
        })
        .catch((err) => {
          console.log("Oh noes, something went wrong: ", err);
        });
    },
    setAsSelected(targetDeckName) {
      this.selectedDeck = targetDeckName;
      for (var i = 0; i < this.decks.length; i++) {
        if (this.decks[i].name !== targetDeckName) {
          this.decks[i].isSelected = false;
        } else {
          this.decks[i].isSelected = true;
        }
      }
    },
    openDeck(targetDeckName) {
      this.setAsSelected(targetDeckName);
      this.$router.push({
        name: "GameMain",
        params: { deckId: this.selectedDeck },
      });
    },
  },
  computed: {
    validDeckSelected() {
      return this.selectedDeck === undefined ? false : true;
    },
    userSelectedDeck() {
      return this.selectedDeck;
    },
  },
  mounted() {
    this.fetchDecks();
  },
};
</script>
<style scoped>
.container-deck-picker {
  text-align: center;
}
.hero-container {
  background-color: var(--var-IIF-teal);
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding-top: 6rem;
  margin-bottom: 4rem;
  background-image: url("../assets/background-stars.png");
  background-size: contain;
}
.hero {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90%;
  max-width: 1100px;
}
.hero-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
}
.hero-image {
  height: 100%;
  display: flex;
  align-items: center;
}
.hero-1 {
  font-size: 6.8rem;
  text-transform: uppercase;
  font-weight: bold;
  color: var(--var-IIF-yellow);
  line-height: 6.3rem;
}
.hero-tagline {
  text-transform: uppercase;
  font-size: 5.6rem;
  line-height: 5.3rem;
  font-weight: bold;
  color: var(--var-IIF-blue);
  letter-spacing: -0.1rem;
  padding-top: 0;
  margin-bottom: 2rem;
}
.hero-blurb {
  color: var(--var-IIF-yellow);
  font-family: var(--var-IIF-sans-body);
  font-size: 2.2rem;
  line-height: 2.9rem;
  font-weight: bold;
  text-transform: uppercase;
  text-align: left;
  box-sizing: border-box;
  max-width: 57.5rem;
  background: linear-gradient(180deg, #63c7c9 0%, rgba(99, 199, 201, 0) 100%),
    var(--var-IIF-blue);
  padding: 2rem;
  border-radius: 0.5rem;
}
p {
  font-family: Helvetica, Arial, sans-serif;
  text-align: left;
  text-align: justify;
  margin-bottom: 0.1rem;
  max-width: 450px;
  color: var(--var-IIF-blue);
}
.hero-image {
  width: 50%;
  height: 60vh;
  background-image: url("../assets/IIF_hand_image-transparent.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

/* SELECT DECK SECTION */
h2 {
  color: var(--var-IIF-blue);
  font-family: var(--var-IIF-sans-heavy);
  font-size: 2.7rem;
  text-transform: uppercase;
  margin: 1.5rem auto 0.5rem auto;
}
h2.double-click-prompt {
  color: var(--var-IIF-teal);
  font-family: var(--var-IIF-sans);
  font-size: 2.4rem;
  margin: 0 auto auto auto;
}

/* DECK BUTTON STYLES */
.deck-lineup {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}
.deck-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem;
  padding: 1rem;
  border-radius: 8px;
  border: 4px solid transparent;
  width: 220px;
}
.deck-button.selected {
  border: 4px solid var(--var-IIF-red);
  /* background-color: var(--var-IIF-teal); */
}
.deck-button.selected h4 {
  color: var(--var-IIF-red);
}
.deck-button.selected .deck-art {
  /* background-color: var(--var-IIF-teal); */
}
.deck-art {
  width: 160px;
  height: 210px;
  background-image: url(../assets/Deck-Box-BG.svg);
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 1rem;
  margin-bottom: 2rem;
}
.deck-name {
  font-size: 2.7rem !important;
  text-align: center;
  font-weight: bolder;
  text-transform: uppercase;
  margin-bottom: 1r4em;
}
.deck-name.selected {
  color: var(--var-IIF-red);
}

.created-by {
  font-family: var(--var-IIF-sans-body);
  font-size: 1.2rem;
  color: black;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 0;
}
.deck-creator-name {
  font-family: var(--var-IIF-sans-body);
  font-size: 1.4rem;
  text-transform: uppercase;
  font-weight: bold;
  margin: 0.2rem auto 0.2rem auto;
  color: black;
}
.blurb {
  font-family: var(--var-IIF-sans-body);
  font-size: 1.4rem;
  color: black;
  text-align: left;
}

/* BUTTONS */
.button-div {
  margin-bottom: 6rem;
}
button {
  font-family: var(--var-IIF-sans-heavy);
  border: 0;
  background-color: var(--var-IIF-teal);
  color: var(--var-IIF-blue);
  text-transform: uppercase;
  font-weight: bolder;
  margin: 1rem;
  padding: 1.8rem 2.8rem 1.5rem 2.8rem;
  font-size: 1.4rem;
}
button:hover {
  transform: translate(0, -4px);
  box-shadow: 0 4px var(--var-IIF-blue);
}
button:active {
  /* transform: translate(0, 4px); */
  box-shadow: 0 -4px var(--var-IIF-blue);
  padding-bottom: calc(1.2rem - 4px);
  margin-top: calc(1rem + 4px);
}
button:disabled {
  opacity: 0.5;
}
</style>
