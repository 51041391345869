<template>
  <div class="navigation">
    <router-link to="/">
      <div class="logo-div">
        <img class="crystal-ball-logo" src="../assets/Nav-Crystal-Ball.png" />
        <h1>
          Investing in Futures
          <span class="deck-name" v-if="gameInSession">
            {{ gameInSession }}
          </span>
        </h1>
      </div>
    </router-link>

    <div class="right-side-links">
      <div id="links-open-in-tab" v-if="gameInSession">
        <router-link :to="{ name: 'ExtendedResources' }" target="_blank"
          >Extended Resources
        </router-link>
        <router-link to="/About" target="_blank">About</router-link>
      </div>
      <div id="links-open-in-self" v-if="!gameInSession">
        <router-link :to="{ name: 'ExtendedResources' }"
          >Extended Resources
        </router-link>
        <router-link to="/About">About</router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Navigation",
  data() {
    return {};
  },
  computed: {
    gameInSession() {
      return this.$route.params.deckId;
    },
  },
  methods: {},
};
</script>
<style scoped>
.navigation {
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  background-color: var(--var-IIF-teal);
  color: var(--var-IIF-dark-teal);
  width: 100vw;
  height: 4.5rem;
  box-sizing: border-box;
  text-transform: uppercase;
  padding-top: 0.2rem;
}
.crystal-ball-logo {
  height: 5rem;
  position: fixed;
  left: 1rem;
  top: 0.4rem;
  margin: 0;
}

.navigation h1 {
  margin: 0 0 0 7rem;
  padding: 0;
  display: inline-block;
  font-size: 2.8rem;
  color: var(--var-IIF-yellow);
}
.navigation h1,
.navigation h1 a {
  text-decoration: none;
  text-transform: uppercase;
}
.navigation h1 .deck-name {
  font-family: "Univers-black", Arial, Helvetica, sans-serif;
  font-size: 2.7rem;
  color: var(--var-IIF-dark-teal);
  text-shadow: 2px 2px rgba(255, 255, 255, 0.5);
}
.navigation .created-by {
  display: inline-block;
  font-family: Arial, Helvetica, sans-serif;
  color: var(--var-IIF-dark-teal);
  text-transform: none;
  margin-left: 1rem;
  font-size: 1.4rem;
}
.navigation .guide-book-btn {
  cursor: pointer;
}
.right-side-links {
  font-family: var(--var-IIF-sans-body);
  font-size: 1.4rem;
  margin-right: 2rem;
}
.right-side-links a {
  color: var(--var-IIF-cyan);
  font-weight: bold;
  padding: 0 1rem;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
</style>
