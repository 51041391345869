import Airtable from "airtable";

export function getAllDecks() {
  return new Promise((resolve, reject) => {
    const API_KEY = process.env.VUE_APP_AIRTABLE_API_KEY;
    var base = new Airtable({ apiKey: API_KEY }).base("appn0WDLC3h9ohCW3");
    var availableDecks = [];
    base("Decks")
      .select({
        // maxRecords: 100,
        fields: ["Name", "Created By", "Deck Blurb"],
        view: "Grid view",
      })
      .eachPage(
        function page(records, fetchNextPage) {
          // This function (`page`) will get called for each page of records.
          records.forEach(function (record) {
            var thisDeck = {};
            thisDeck.name = record.get("Name");
            thisDeck.creator = record.get("Created By");
            thisDeck.blurb = record.get("Deck Blurb");
            //TODO: Setup color and icon props
            //setup props for DeckPicker page
            thisDeck.isSelected = false;
            availableDecks.push(thisDeck);
            console.log("thisDeck: ", thisDeck);
          });
          // To fetch the next page of records, call `fetchNextPage`.
          // If there are more records, `page` will get called again.
          // If there are no more records, `done` will get called.
          fetchNextPage();
        },
        function done(err) {
          if (err) {
            reject(err);
          } else {
            resolve(availableDecks);
          }
        }
      );
  });
}
