<template>
  <Navigation />
  <router-view />
</template>

<script>
// @ is an alias to /src
import Navigation from "@/components/TheNavigation.vue";
export default {
  components: {
    Navigation,
  },
};
</script>

<style>
@font-face {
  font-family: "univers_condensedmedium";
  src: url("../public/unvr57x-webfont.woff2") format("woff2"),
    url("../public/unvr57x-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Univers-black";
  src: url("../public/UniversLTStd-XBlack.woff2") format("woff2"),
    url("../public/UniversLTStd-XBlack.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

/* @font-face {
  font-family: "univers_condensedbold";
  src: url("../public/unvr67x-webfont.woff2") format("woff2"),
    url("../public/unvr67x-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
} */
:root {
  --var-IIF-yellow: #efff33;
  --var-IIF-teal: #63c7c9;
  --var-IIF-cyan: #c1fafb;
  --var-IIF-dark-teal: #309b9d;
  --var-IIF-blue: #0012b4;
  --var-IIF-red: #ff0000;
  --var-IIF-sans-body: Helvetica, Arial, sans-serif;
  --var-IIF-sans: "univers_condensedmedium", Avenir, Helvetica, Arial,
    sans-serif;
  --var-IIF-sans-heavy: "Univers-black", Arial, Helvetica, sans-serif;
}
#app {
  font-family: "univers_condensedmedium", Avenir, Helvetica, Arial, sans-serif;
  font-size: 1.4rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
html {
  font-size: 10px;
}
body {
  margin: 0;
  background-color: var(--var-IIF-yellow);
}
.container {
  padding-top: 3.15rem;
}

/* MARKDOWN STYLING */
.markdown h1 {
  font-family: var(--var-IIF-sans-heavy);
  font-size: 3.2rem;
  color: var(--var-IIF-blue);
}
.markdown h2 {
  font-size: 2.8rem;
}
.markdown h3 {
  font-family: var(--var-IIF-sans-heavy);
  font-size: 2rem;
  color: var(--var-IIF-dark-teal);
}
.markdown p {
  font-family: var(--var-IIF-sans-body);
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: black;
}
.markdown blockquote {
  border-left: 4px solid var(--var-IIF-dark-teal);
  padding-left: 2rem;
}
.markdown ul li {
  color: black;
  font-family: var(--var-IIF-sans-body);
  font-size: 1.6rem;
  line-height: 2.2rem;
  margin-bottom: 1rem;
}
.markdown ul {
  margin-bottom: 3rem;
}
</style>
